import * as React from "react";
import "../styles/style.css";

// markup
const AboutPage = () => {
  const toggleNav = () => {
    const mobileNav = document.getElementById("mobileNav");
    mobileNav.classList.toggle("visible");
  };
  return (
    <main>
      <title>Alexandra Rusu - Despre mine</title>
      <header>
        <span>Dr. Alexandra Rusu</span>
        <nav>
          <a href="/">Prima pagina</a>
          <a href="/about">Despre mine</a>
          <a href="/cases">Cazuri</a>
          <a href="/treatment">Tratamente și tarife</a>
          <a href="/contact">Contact</a>
        </nav>
        <button className="menu-trigger" onClick={toggleNav}>
          Menu
        </button>
      </header>
      <div className="mobile-nav" id="mobileNav">
        <nav>
          <a href="/">Prima pagina</a>
          <a href="/about">Despre mine</a>
          <a href="/cases">Cazuri</a>
          <a href="/treatment">Tratamente și tarife</a>
          <a href="/contact">Contact</a>
        </nav>
      </div>
      <section className="about-page">
        <h1>Despre mine</h1>
        <div className="text">
          <p>
            Timp de 12 ani am frecventat secția germană matern a Colegiului
            Național “George Coșbuc” din Cluj Napoca, perioadă în care am
            dezvoltat un interes pentru biologie, anatomie și fiziologie. Am
            ales să particip la olimpiade de nivel local, județean și național
            la această disciplină.
          </p>
          <p>
            Apoi, am studiat timp de 6 ani medicină dentară la UMF “Iuliu
            Hațieganu” și am câștigat o bursă de studiu la Universitatea de
            Medicină din Graz, Austria. Acolo am avut ocazia să primesc o alta
            perspectivă asupra învățământului medical, relației medic pacient și
            a ortodonției.
          </p>
          <p>
            Am finalizat studiile superioare prin examenul de licență cu media
            9.85.
          </p>
          <p>
            În 2014 am susținut examenul de rezidențiat și datorită rezultatelor
            foarte bune am reușit să mă specializez timp de 3 ani în ortodonție
            și ortopedie dento-facială. În anul 2017 am obținut diploma de medic
            specialist.
          </p>
          <p>
            În primii ani de carieră am tratat numeroși pacienți prin colaborări
            în Cluj, Satu-Mare, Maramureș, Alba și Bihor.
          </p>
          <p>
            Momentan ofer consultații ortodontice în Cluj-Napoca, Oradea și
            Turda.
          </p>
        </div>
      </section>
      <section className="courses">
        <h3>Cursuri</h3>
        <div className="course">
          <span>Octombrie 2021</span>
          <p>
            Webinarii Dr. Mariano Rocabado, Dr. Roberto Gutiérrez - “Functional
            dentistry”
          </p>
        </div>
        <div className="course">
          <span>Noiembrie 2020</span>
          <p>
            51th SIDO international congress - Societa italiana di ortodonzia -
            “Contemporary Orthodontics and Future Trends”
          </p>
        </div>
        <div className="course">
          <span>Octombrie 2018</span>
          <p>
            49th SIDO international congress - Societa italiana di ortodonzia -
            “Orthodontic challenges: borderlines, doubts, errors, teamwork,
            that’s the way”
          </p>
        </div>
        <div className="course">
          <span>Martie 2018</span>
          <p>
            D.D.S, Dr. Odont, Dr. Birte Melsen - “Adult patient - where is the
            limit”
          </p>
        </div>
        <div className="course">
          <span>Noiembrie 2017</span>
          <p>
            Dr. Raffaele Spena - “Biological and biomechanical efficiency in
            different age periods”
          </p>
        </div>
        <div className="course">
          <span>Mai 2017</span>
          <p>
            Prof. Dr. Joseph Bouserhal - “How to push the limits in adult
            orthodontics”
          </p>
        </div>
        <div className="course">
          <span>Mai 2017</span>
          <p>
            The 8th international congress of orthodontics - “From the
            prevention of malocclusions to the complex multidisciplinary
            treatment”
          </p>
        </div>
        <div className="course">
          <span>Aprilie 2017</span>
          <p>
            The 16th biennial congress of the international academy of
            periodontology - precongress symposium “the ortho-perio connection”
          </p>
        </div>
        <div className="course">
          <span>Martie 2016</span>
          <p>
            Prof. Dr. Adrian Becker, Prof. Dr. Stella Chaushu - The Orthodontic
            Treatment of Impacted Teeth
          </p>
        </div>
        <div className="course">
          <span>Octombrie 2015</span>
          <p>
            Prof. Dr. Roland  Mannchen – Tratamentul ortodontic  fix in dentatia
            mixta, dimensiunea transversala/asimetrii (hands-on)
          </p>
        </div>
        <div className="course">
          <span>Mai 2015</span>
          <p>
            Prof. Dr. Marie Boileau (Univ. Bordeaux) – Tehnica Straightwire,
            Diagnosticul si tratamentul anomaliilor cls. II
          </p>
        </div>
        <div className="course">
          <span>Februarie 2015</span>
          <p>
            Prof. Dr. Marie Boileau (Univ. Bordeaux) – Introducere in
            Ortodontie, Tehnica Edgewise (hands-on)
          </p>
        </div>
      </section>
      <footer>
        <div className="col">
          <h3>
            Alexandra Rusu
            <br />
            <span>— medic specialist ortodontie</span>
            <br />
            <small>© 2021</small>
          </h3>
        </div>
        <div className="col">
          <a href="/contact">Contact</a>
        </div>
      </footer>
    </main>
  );
};

export default AboutPage;
